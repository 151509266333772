<template>
	<errorContainer :error="error" :warning="warning">
		<b-container fluid>
			<b-form @submit.prevent="onSubmit">
				<b-row>
					<b-col cols="12">
						<b-form-group :label="FormMSG(1, 'Name')" label-for="name" :label-cols="3" :horizontal="true">
							<b-form-input
								v-model.trim="contact.name"
								type="text"
								:placeholder="FormMSG(2, 'Name ...')"
								:class="{ 'is-invalid': isSubmitted && $v.contact.name.$error }"
							/>
							<div v-if="isSubmitted && !$v.contact.name.required" class="invalid-feedback">
								{{ FormMSG(12, 'Please, length should be at least 2') }}
							</div>
						</b-form-group>
						<b-form-group :label="FormMSG(3, 'First Name')" label-for="firstName" :label-cols="3" :horizontal="true">
							<b-form-input
								v-model.trim="contact.firstName"
								type="text"
								:placeholder="FormMSG(4, 'First Name ...')"
								:class="{ 'is-invalid': isSubmitted && $v.contact.firstName.$error }"
							/>
							<div v-if="isSubmitted && !$v.contact.firstName.required" class="invalid-feedback">
								{{ FormMSG(13, 'Please, length should be at least 2') }}
							</div>
						</b-form-group>
						<b-form-group :label="FormMSG(5, 'Phone Number')" label-for="phone" :label-cols="3" :horizontal="true">
							<b-form-input
								v-model.trim="contact.phone"
								type="text"
								:placeholder="FormMSG(6, 'Phone Number ...')"
								:class="{ 'is-invalid': isSubmitted && $v.contact.phone.$error }"
							/>
							<div v-if="isSubmitted && !$v.contact.phone.phoneNumberValid" class="invalid-feedback">
								{{ FormMSG(14, 'Please, enter valid phone number') }}
							</div>
						</b-form-group>
						<b-form-group :label="FormMSG(7, 'Email')" label-for="email" :label-cols="3" :horizontal="true">
							<b-form-input
								v-model.trim="contact.email"
								type="text"
								:placeholder="FormMSG(8, 'Email ...')"
								:class="{ 'is-invalid': isSubmitted && $v.contact.email.$error }"
							/>
							<div v-if="isSubmitted && !$v.contact.email.emailValid" class="invalid-feedback">
								{{ FormMSG(15, 'Please, enter valid email') }}
							</div>
						</b-form-group>
						<b-form-group :label="FormMSG(9, 'Function')" label-for="function" :label-cols="3" :horizontal="true">
							<b-form-input v-model="contact.function" type="text" :placeholder="FormMSG(10, 'Function ...')" />
						</b-form-group>
						<div class="w-100">
							<b-col cols="3" class="pull-right px-0">
								<b-form-group>
									<b-button type="submit" variant="primary" size="md" block>
										<span>{{ FormMSG(11, 'Save') }}</span>
									</b-button>
								</b-form-group>
							</b-col>
						</div>
					</b-col>
				</b-row>
			</b-form>
		</b-container>
	</errorContainer>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { isNil, emailValid, phoneNumberValid } from '@/shared/utils';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import { addUpdateFilmCommissionContact, addUpdateSupplierAttributeContact } from '@/cruds/contact.crud';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'ContactForm',
	mixins: [languageMessages, validationMixin, globalMixin],
	props: {
		supplierId: {
			type: [String, Number],
			required: true,
			default: '0'
		},
		editContact: {
			type: Object,
			required: false,
			default: () => {}
		},
		isUpdateAction: {
			type: Boolean,
			required: true,
			default: true
		},
		supplierType: {
			type: Number,
			required: false,
			default: 2 // 0 = label, 1 = source, 2 = commission
		}
	},
	data() {
		return {
			error: {},
			warning: '',
			contact: {},
			isSubmitted: false
		};
	},
	methods: {
		async onSubmit(e) {
			e.preventDefault();
			this.isSubmitted = true;

			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}

			const contact = {
				...this.contact
			};

			if (!this.isUpdateAction) {
				// add
				if (this.supplierType === 2) {
					await addUpdateFilmCommissionContact(this.supplierId, contact)
						.then((record) => {
							this.$emit('form:updated', record);
						})
						.catch((e) => {
							console.error(e);
						})
						.finally(() => {
							this.createToastForMobile(this.FormMSG(16, 'Success'), this.FormMSG(17, 'Contact added successfully'), '');
						});
				} else {
					await addUpdateSupplierAttributeContact(this.supplierId, contact)
						.then((record) => {
							this.$emit('form:updated', record);
						})
						.catch((e) => {
							console.error(e);
						})
						.finally(() => {
							this.createToastForMobile(this.FormMSG(16, 'Success'), this.FormMSG(17, 'Contact added successfully'), '');
						});
				}
			} else {
				// update
				if (this.supplierType === 2) {
					await addUpdateFilmCommissionContact(this.supplierId, contact, this.contact.id)
						.then((record) => {
							this.$emit('form:updated', record);
						})
						.catch((e) => {
							console.error(e);
						})
						.finally(() => {
							this.createToastForMobile(this.FormMSG(16, 'Success'), this.FormMSG(18, 'Contact updated successfully'), '');
						});
				} else {
					await addUpdateSupplierAttributeContact(this.supplierId, contact, this.contact.id)
						.then((record) => {
							this.$emit('form:updated', record);
						})
						.catch((e) => {
							console.error(e);
						})
						.finally(() => {
							this.createToastForMobile(this.FormMSG(16, 'Success'), this.FormMSG(18, 'Contact updated successfully'), '');
						});
				}
			}
		}
	},
	validations: {
		contact: {
			name: {
				required,
				min: minLength(2)
			},
			firstName: {
				required,
				min: minLength(2)
			},
			phone: {
				required,
				phoneNumberValid
			},
			email: {
				required,
				emailValid
			}
		}
	},
	watch: {
		editContact: {
			async handler(n) {
				if (!isNil(n)) {
					this.contact = n;
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
