var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.error, warning: _vm.warning } },
    [
      _c(
        "b-modal",
        {
          ref: "contact-form-modal",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            size: "lg",
            "ok-only": "",
            "ok-variant": "light",
            "ok-title": _vm.FormMSG(6, "Close"),
          },
          on: { ok: _vm.onCancel, hidden: _vm.onCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [_vm._v("\n\t\t\t" + _vm._s(_vm.modalTitle) + "\n\t\t")]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c("div", { staticClass: "inline-block" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-row justify-content-end pb-3" },
              [
                _c(
                  "b-col",
                  { attrs: { cols: "3" } },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { block: "", variant: "primary" },
                        on: { click: _vm.addContact },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(1, "New contact")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex flex-row" },
              [
                _c("list", {
                  attrs: {
                    "supplier-id": _vm.supplierId,
                    "supplier-type": _vm.supplierType,
                    refresh: _vm.refreshList,
                  },
                  on: { "list:edited": _vm.onEditForm },
                }),
              ],
              1
            ),
          ]),
          _c("form-modal", {
            attrs: {
              "supplier-id": _vm.supplierId,
              "supplier-type": _vm.supplierType,
              "edit-contact": _vm.contact,
              "hide-cancel-action": _vm.isUpdateAction,
            },
            on: {
              "contact-form-modal:cancelled": _vm.onCloseFormModal,
              "contact-form-modal:confirmed": _vm.onConfirmFormModal,
              "contact-form-modal:updated": _vm.onUpdateFormModal,
            },
            model: {
              value: _vm.isOpenFormModal,
              callback: function ($$v) {
                _vm.isOpenFormModal = $$v
              },
              expression: "isOpenFormModal",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }