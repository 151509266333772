import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

/**
 * @param {String|Number} contactId
 * @param {Object} updatedContact
 */
export const addUpdateContact = async (updatedContact = {}, contactId = 0) => {
	try {
		const mutation = gql`
			mutation ($contactId: ID!, $updatedContact: ContactInput!) {
				AddUpdContact(ContactId: $contactId, UpdatedContact: $updatedContact) {
					id
					name
					firstName
					phone
					email
					function
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				contactId: parseInt(contactId, 10),
				updatedContact
			}
		});

		return data.AddUpdContact;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * @param {String|Number} filmCommissionId
 * @param {String|Number} contactId
 * @param {Object} updatedContact
 */
export const addUpdateFilmCommissionContact = async (filmCommissionId, updatedContact = {}, contactId = 0) => {
	try {
		const mutation = gql`
			mutation ($filmCommissionId: ID!, $contactId: ID!, $updatedContact: ContactInput!) {
				AddUpdFilmCommissionContact(FilmCommissionId: $filmCommissionId, ContactId: $contactId, UpdatedContact: $updatedContact) {
					id
					name
					firstName
					phone
					email
					function
					isMain
				}
			}
		`;

		const {
			data: { AddUpdFilmCommissionContact }
		} = await apollo.mutate({
			mutation,
			variables: {
				filmCommissionId: parseInt(filmCommissionId, 10),
				contactId: parseInt(contactId, 10),
				updatedContact
			}
		});

		return AddUpdFilmCommissionContact;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * @param {String|Number} supplierAttributeId
 * @param {String|Number} contactId
 * @param {Object} updatedContact
 */
export const addUpdateSupplierAttributeContact = async (supplierAttributeId, updatedContact = {}, contactId = 0) => {
	try {
		const mutation = gql`
			mutation ($supplierAttributeId: ID!, $contactId: ID!, $updatedContact: ContactInput!) {
				AddUpdSupplierAttributeContact(SupplierAttributeId: $supplierAttributeId, ContactId: $contactId, UpdatedContact: $updatedContact) {
					id
					name
					firstName
					phone
					email
					function
					isMain
				}
			}
		`;

		const {
			data: { AddUpdSupplierAttributeContact }
		} = await apollo.mutate({
			mutation,
			variables: {
				supplierAttributeId: parseInt(supplierAttributeId, 10),
				contactId: parseInt(contactId, 10),
				updatedContact
			}
		});

		return AddUpdSupplierAttributeContact;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * @param {String|Number} contactId
 */
export const delContact = async (contactId) => {
	try {
		const mutation = gql`
			mutation ($contactId: ID!) {
				DelContact(ContactId: $contactId)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				contactId: parseInt(contactId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return data.DelContact;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * @param {String|Number} contactId
 * @param {String|Number} filmCommissionId
 */
export const delFilmCommissionContact = async (contactId, filmCommissionId) => {
	try {
		const mutation = gql`
			mutation ($contactId: ID!, $filmCommissionId: ID!) {
				DelfilmCommissionContact(ContactId: $contactId, FilmCommissionId: $filmCommissionId)
			}
		`;

		const {
			data: { DelfilmCommissionContact }
		} = await apollo.mutate({
			mutation,
			variables: {
				contactId: parseInt(contactId, 10),
				filmCommissionId: parseInt(filmCommissionId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return DelfilmCommissionContact;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * @param {String|Number} contactId
 * @param {String|Number} supplierAttributeId
 */
export const delSupplierAttributeContact = async (contactId, supplierAttributeId) => {
	try {
		const mutation = gql`
			mutation ($contactId: ID!, $supplierAttributeId: ID!) {
				DelSupplierAttributeContact(ContactId: $contactId, SupplierAttributeId: $supplierAttributeId)
			}
		`;

		const {
			data: { DelSupplierAttributeContact }
		} = await apollo.mutate({
			mutation,
			variables: {
				contactId: parseInt(contactId, 10),
				supplierAttributeId: parseInt(supplierAttributeId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return DelSupplierAttributeContact;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * @param {String|Number} contactId
 */
export const getContact = async (contactId) => {
	try {
		const {
			data: { GetContact }
		} = await apollo.query({
			query: gql`
				query ($contactId: ID!) {
					GetContact(ContactId: $contactId) {
						id
						name
						firstName
						phone
						email
						function
					}
				}
			`,
			variables: {
				contactId: parseInt(contactId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetContact;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * @param {String|Number} contactId
 * @param {String|Number} filmCommissionId
 */
export const getFilmCommissionContact = async (contactId, filmCommissionId) => {
	try {
		const {
			data: { GetContactFilmCom }
		} = await apollo.query({
			query: gql`
				query ($contactId: ID!, $filmCommissionId: ID!) {
					GetContactFilmCom(ContactId: $contactId, FilmCommissionId: $filmCommissionId) {
						id
						name
						firstName
						phone
						email
						function
					}
				}
			`,
			variables: {
				contactId: parseInt(contactId, 10),
				filmCommissionId: parseInt(filmCommissionId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetContactFilmCom;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * @param {String|Number} contactId
 * @param {String|Number} supplierAttributeId
 */
export const getSuppAttributeContact = async (contactId, supplierAttributeId) => {
	try {
		const {
			data: { GetContactSuppAttribute }
		} = await apollo.query({
			query: gql`
				query ($contactId: ID!, $supplierAttributeId: ID!) {
					GetContactSuppAttribute(ContactId: $contactId, SupplierAttributeId: $supplierAttributeId) {
						id
						name
						firstName
						phone
						email
						function
						isMain
					}
				}
			`,
			variables: {
				contactId: parseInt(contactId, 10),
				supplierAttributeId: parseInt(supplierAttributeId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetContactSuppAttribute;
	} catch (e) {
		console.log({ e });
	}
};

export const getContacts = async () => {
	try {
		const {
			data: { GetContacts }
		} = await apollo.query({
			query: gql`
				query {
					GetContacts {
						id
						name
						firstName
						phone
						email
						function
					}
				}
			`,
			fetchPolicy: 'no-cache'
		});

		return GetContacts;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * @param {String|Number} filmCommissionId
 */
export const getFilmCommissionContacts = async (filmCommissionId) => {
	try {
		const {
			data: { GetContactsFilmCom }
		} = await apollo.query({
			query: gql`
				query ($filmCommissionId: ID!) {
					GetContactsFilmCom(FilmCommissionId: $filmCommissionId) {
						id
						name
						firstName
						phone
						email
						function
						isMain
					}
				}
			`,
			variables: {
				filmCommissionId: parseInt(filmCommissionId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetContactsFilmCom;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * @param {String|Number} supplierAttributeId
 */
export const getSuppAttributeContacts = async (supplierAttributeId) => {
	try {
		const {
			data: { GetContactsSuppAttribute }
		} = await apollo.query({
			query: gql`
				query ($supplierAttributeId: ID!) {
					GetContactsSuppAttribute(SupplierAttributeId: $supplierAttributeId) {
						id
						name
						firstName
						phone
						email
						function
						isMain
					}
				}
			`,
			variables: {
				supplierAttributeId: parseInt(supplierAttributeId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetContactsSuppAttribute;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * @param {String|Number} filmCommissionId
 * @param {String|Number} contactId
 */
export const updateFilmCommissionMainContact = async (filmCommissionId, contactId) => {
	try {
		const mutation = gql`
			mutation ($filmCommissionId: ID!, $contactId: ID!) {
				UpdFilmCommissionMainContact(FilmCommissionId: $filmCommissionId, ContactId: $contactId)
			}
		`;

		const {
			data: { UpdFilmCommissionMainContact }
		} = await apollo.mutate({
			mutation,
			variables: {
				filmCommissionId: parseInt(filmCommissionId, 10),
				contactId: parseInt(contactId, 10)
			}
		});

		return UpdFilmCommissionMainContact;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * @param {String|Number} supplierAttributeId
 * @param {String|Number} contactId
 */
export const updateSupplierAttributeMainContact = async (supplierAttributeId, contactId) => {
	try {
		const mutation = gql`
			mutation ($supplierAttributeId: ID!, $contactId: ID!) {
				UpdSupplierAttributeMainContact(SupplierAttributeId: $supplierAttributeId, ContactId: $contactId)
			}
		`;

		const {
			data: { UpdSupplierAttributeMainContact }
		} = await apollo.mutate({
			mutation,
			variables: {
				supplierAttributeId: parseInt(supplierAttributeId, 10),
				contactId: parseInt(contactId, 10)
			}
		});

		return UpdSupplierAttributeMainContact;
	} catch (e) {
		console.error({ e });
	}
};
