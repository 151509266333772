var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "slide" } }, [
    _c("div", { staticClass: "w-100" }, [
      _c(
        "div",
        { staticClass: "flex-row" },
        [
          _c(
            "b-col",
            { staticClass: "pt-2 pb-3", attrs: { cols: "12", xl: "12" } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: {
                    label: _vm.FormMSG(12, "Filter"),
                    "label-cols": "1",
                    "label-align-sm": "left",
                    "label-size": "sm",
                    "label-for": "filterInput",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { size: "sm" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          type: "search",
                          id: "filterInput",
                          placeholder: _vm.FormMSG(13, "Type to Search"),
                        },
                        model: {
                          value: _vm.filter,
                          callback: function ($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { disabled: !_vm.filter },
                              on: {
                                click: function ($event) {
                                  _vm.filter = ""
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-times",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex-row" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", xl: "12" } },
            [
              _c("b-table", {
                ref: "contactTable",
                attrs: {
                  "sticky-header": "600px",
                  responsive: "sm",
                  "selected-variant": _vm.selectedColor,
                  hover: _vm.hover,
                  "select-mode": _vm.selectMode,
                  items: _vm.contacts,
                  fields: _vm.fields,
                  "head-variant": _vm.hv,
                  "empty-text": _vm.FormMSG(1, "No contact found"),
                  "current-page": _vm.currentPage,
                  filter: _vm.filter,
                  "per-page": _vm.perPage,
                  "tbody-tr-class": "p-0",
                  selectable: "",
                  bordered: "",
                  small: "",
                  "show-empty": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(edit)",
                    fn: function (data) {
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-center" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "btn bg-transparent border-0",
                                attrs: { variant: "primary", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editContact(data.item)
                                  },
                                },
                              },
                              [
                                _c(_vm.getLucideIcon(_vm.ICONS.EDIT.name), {
                                  tag: "component",
                                  attrs: {
                                    color: _vm.ICONS.EDIT.color,
                                    size: 20,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(isMain)",
                    fn: function (data) {
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-center" },
                          [
                            _c("b-form-radio", {
                              staticClass: "cursor-pointer",
                              attrs: {
                                name: "contact-main",
                                size: "md",
                                "aria-describedby": "ariaDescribedby",
                                value: data.item.id,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.changeMainContact(data.item.id)
                                },
                              },
                              model: {
                                value: _vm.mainSelected,
                                callback: function ($$v) {
                                  _vm.mainSelected = $$v
                                },
                                expression: "mainSelected",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(delete)",
                    fn: function (data) {
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-center" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "btn bg-transparent border-0",
                                attrs: { variant: "danger", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteContact(data.item.id)
                                  },
                                },
                              },
                              [
                                _c(_vm.getLucideIcon(_vm.ICONS.TRASH.name), {
                                  tag: "component",
                                  attrs: {
                                    color: _vm.ICONS.TRASH.color,
                                    size: 20,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }