var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group", attrs: { role: "group" } },
    [
      _vm.showDefaultLabel
        ? _c("label", { staticClass: "d-block" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.label) + "\n\t"),
          ])
        : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "v-select-validator-wrapper",
              attrs: { md: "8", lg: "8" },
            },
            [
              _c("v-select", {
                class: {
                  "is-invalid":
                    _vm.isSubmitted && _vm.$v.selectedContact.$error,
                },
                attrs: {
                  id: "contact-selector",
                  multiple: "",
                  placeholder: _vm.placeholder,
                  options: _vm.contacts,
                  label: "fullName",
                  noDrop: "",
                  disabled: _vm.disabledSelect,
                  loading: _vm.loading,
                },
                on: {
                  "option:selected": _vm.addContact,
                  "option:deselected": _vm.removeContact,
                  search: _vm.handleSearch,
                },
                model: {
                  value: _vm.selectedContact,
                  callback: function ($$v) {
                    _vm.selectedContact = $$v
                  },
                  expression: "selectedContact",
                },
              }),
              _vm.isSubmitted && !_vm.$v.selectedContact.required
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(_vm.errorMessage) + "\n\t\t\t"
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c("b-col", { attrs: { md: "4", lg: "4" } }, [
            _c(
              "div",
              { staticClass: "selector_actions_btns has_two_btn" },
              [
                _c(
                  "b-button",
                  {
                    style: `${
                      _vm.$screen.width >= 992 ? "line-height:26px;" : ""
                    }`,
                    attrs: { disabled: _vm.disabled },
                    on: { click: _vm.openContactModal },
                  },
                  [_c("contact", { attrs: { color: "#FFFFFF", size: 20 } })],
                  1
                ),
                _c(
                  "b-button",
                  {
                    style: `${
                      _vm.$screen.width >= 992 ? "line-height:26px;" : ""
                    }`,
                    attrs: {
                      block: "",
                      variant: "outline-primary",
                      disabled: _vm.disabled,
                    },
                    on: { click: _vm.openContactModal },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" + _vm._s(_vm.listLabel) + "\n\t\t\t\t"
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("list-modal", {
        attrs: {
          "supplier-id": _vm.supplierId,
          "supplier-type": _vm.supplierType,
          "modal-title": _vm.modalTitle,
        },
        on: { "contact-list-modal:cancelled": _vm.onCancelModal },
        model: {
          value: _vm.isModalOpen,
          callback: function ($$v) {
            _vm.isModalOpen = $$v
          },
          expression: "isModalOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }