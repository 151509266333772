<template>
	<transition name="slide">
		<div class="w-100">
			<div class="flex-row">
				<b-col cols="12" xl="12" class="pt-2 pb-3">
					<b-form-group :label="FormMSG(12, 'Filter')" label-cols="1" label-align-sm="left" label-size="sm" label-for="filterInput" class="mb-0">
						<b-input-group size="sm">
							<b-form-input v-model="filter" type="search" id="filterInput" :placeholder="FormMSG(13, 'Type to Search')"></b-form-input>
							<b-input-group-append>
								<b-button :disabled="!filter" @click="filter = ''">
									<i class="fa fa-times" aria-hidden="true"></i>
								</b-button>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
				</b-col>
			</div>
			<div class="flex-row">
				<b-col cols="12" xl="12">
					<b-table
						sticky-header="600px"
						responsive="sm"
						ref="contactTable"
						:selected-variant="selectedColor"
						:hover="hover"
						:select-mode="selectMode"
						:items="contacts"
						:fields="fields"
						:head-variant="hv"
						:empty-text="FormMSG(1, 'No contact found')"
						:current-page="currentPage"
						:filter="filter"
						:per-page="perPage"
						tbody-tr-class="p-0"
						selectable
						bordered
						small
						show-empty
					>
						<template v-slot:cell(edit)="data">
							<div class="d-flex justify-content-center">
								<b-button variant="primary" size="sm" @click="editContact(data.item)" class="btn bg-transparent border-0">
									<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
								</b-button>
							</div>
						</template>
						<template v-slot:cell(isMain)="data">
							<div class="d-flex justify-content-center">
								<b-form-radio
									v-model="mainSelected"
									name="contact-main"
									size="md"
									class="cursor-pointer"
									@change="changeMainContact(data.item.id)"
									aria-describedby="ariaDescribedby"
									:value="data.item.id"
								/>
							</div>
						</template>
						<template v-slot:cell(delete)="data">
							<div class="d-flex justify-content-center">
								<b-button variant="danger" size="sm" class="btn bg-transparent border-0" @click="deleteContact(data.item.id)">
									<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
								</b-button>
							</div>
						</template>
					</b-table>
				</b-col>
			</div>
		</div>
	</transition>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import {
	getFilmCommissionContacts,
	delFilmCommissionContact,
	getSuppAttributeContacts,
	delSupplierAttributeContact,
	updateFilmCommissionMainContact,
	updateSupplierAttributeMainContact
} from '@/cruds/contact.crud';
import { isNil } from '@/shared/utils';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'ContactList',
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			required: false,
			default: true
		},
		refresh: {
			type: Boolean,
			required: false,
			default: false
		},
		supplierId: {
			type: [String, Number],
			required: true,
			default: '0'
		},
		supplierType: {
			type: Number,
			required: false,
			default: 2 // 0 = label, 1 = source, 2 = commission
		}
	},
	computed: {
		baseFields() {
			return [
				{
					key: 'isMain',
					label: this.FormMSG(19, 'Main'),
					formatter: (v, z, root) => {
						if (v === true) {
							this.mainSelected = root.id;
						}
					},
					class: 'text-center'
				},
				{
					key: 'firstName',
					label: this.FormMSG(7, 'First Name'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'name',
					label: this.FormMSG(6, 'Name'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'phone',
					label: this.FormMSG(8, 'Phone Number'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'email',
					label: this.FormMSG(18, 'Email'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'function',
					label: this.FormMSG(9, 'Function'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'edit',
					label: this.FormMSG(10, 'Edit'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				},
				{
					key: 'delete',
					label: this.FormMSG(11, 'Remove'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		fields() {
			return this.baseFields;
		}
	},
	data() {
		return {
			filter: '',
			currentPage: 1,
			perPage: 0,
			selectMode: 'single',
			selectedColor: 'primary',
			hv: 'dark',
			contacts: [],
			mainSelected: ''
		};
	},
	async created() {
		await this.reloadData();
	},
	methods: {
		editContact(item) {
			this.$emit('list:edited', item);
		},
		deleteContact(id) {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(14, 'Are you sure to remove this contact ?'), {
					title: this.FormMSG(15, 'Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(16, 'YES'),
					cancelTitle: this.FormMSG(17, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;
					if (this.supplierType === 2) {
						await delFilmCommissionContact(id, this.supplierId).then(async (result) => {
							if (result) {
								await this.reloadData();
							}
						});
					} else {
						await delSupplierAttributeContact(id, this.supplierId).then(async (result) => {
							if (result) {
								await this.reloadData();
							}
						});
					}
				})
				.catch((err) => console.log(err));
		},
		async reloadData() {
			let contacts = [];

			if (this.supplierType === 2) {
				contacts = await getFilmCommissionContacts(this.supplierId);
			} else {
				contacts = await getSuppAttributeContacts(this.supplierId);
			}

			this.contacts = contacts;
		},
		async changeMainContact(id) {
			// update
			if (this.supplierType === 2) {
				await updateFilmCommissionMainContact(this.supplierId, id);
			} else {
				await updateSupplierAttributeMainContact(this.supplierId, id);
			}
		}
	},
	watch: {
		refresh: {
			async handler(n) {
				if (!isNil(n) && n) {
					// console.log({r: n});
					await this.reloadData();
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss" scoped>
.custom-control {
	padding-left: 1.85rem;
}

.cursor-pointer {
	cursor: pointer;
}
</style>
