<template>
	<div role="group" class="form-group">
		<label class="d-block" v-if="showDefaultLabel">
			{{ label }}
		</label>
		<b-row>
			<b-col md="8" lg="8" class="v-select-validator-wrapper">
				<v-select
					id="contact-selector"
					multiple
					v-model="selectedContact"
					:placeholder="placeholder"
					:options="contacts"
					label="fullName"
					noDrop
					@option:selected="addContact"
					@option:deselected="removeContact"
					:disabled="disabledSelect"
					:loading="loading"
					@search="handleSearch"
					:class="{ 'is-invalid': isSubmitted && $v.selectedContact.$error }"
				></v-select>
				<div v-if="isSubmitted && !$v.selectedContact.required" class="invalid-feedback">
					{{ errorMessage }}
				</div>
			</b-col>
			<b-col md="4" lg="4">
				<div class="selector_actions_btns has_two_btn">
					<b-button :disabled="disabled" :style="`${$screen.width >= 992 ? 'line-height:26px;' : ''}`" @click="openContactModal">
						<contact color="#FFFFFF" :size="20" />
					</b-button>
					<b-button
						block
						variant="outline-primary"
						:disabled="disabled"
						:style="`${$screen.width >= 992 ? 'line-height:26px;' : ''}`"
						@click="openContactModal"
					>
						{{ listLabel }}
					</b-button>
				</div>
			</b-col>
		</b-row>
		<list-modal
			:supplier-id="supplierId"
			:supplier-type="supplierType"
			v-model="isModalOpen"
			:modal-title="modalTitle"
			@contact-list-modal:cancelled="onCancelModal"
		/>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import ListModal from '@/components/Contact/ListModal';
import { PlusCircle, Edit, ListOrdered, Contact } from 'lucide-vue';
import { getFilmCommissionContacts, getSuppAttributeContacts } from '@/cruds/contact.crud';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { isNil } from '@/shared/utils';

export default {
	name: 'ContactSelector',
	mixins: [languageMessages, validationMixin],
	components: {
		ListModal,
		PlusCircle,
		Edit,
		ListOrdered,
		Contact
	},
	props: {
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		placeholder: {
			type: String,
			required: false,
			default: 'Choose a contact'
		},
		label: {
			type: String,
			required: false,
			default: 'Contacts'
		},
		listLabel: {
			type: String,
			required: false,
			default: 'Contact List'
		},
		showDefaultLabel: {
			type: Boolean,
			required: false,
			default: true
		},
		modalTitle: {
			type: String,
			required: true,
			default: ''
		},
		isSubmitted: {
			type: Boolean,
			required: false,
			default: false
		},
		errorMessage: {
			type: String,
			required: false,
			default: null
		},
		supplierId: {
			type: [String, Number],
			required: true,
			default: '0'
		},
		disabledSelect: {
			type: Boolean,
			required: false,
			default: true
		},
		supplierType: {
			type: Number,
			required: false,
			default: 2 // 0 = label, 1 = source, 2 = commission
		}
	},
	data() {
		return {
			isModalOpen: false,
			selectedContact: null,
			loading: false,
			canModify: false,
			rawContacts: [],
			contacts: []
		};
	},
	async created() {
		await this.reloadData();
	},
	methods: {
		addContact(payload) {
			// console.log({addContact: this.selectedContact});
			this.$emit('contact-selector:contacts', this.selectedContact);
		},
		removeContact(payload) {
			// console.log({removeContact: this.selectedContact});
			this.$emit('contact-selector:contacts', this.selectedContact);
		},
		openContactModal() {
			this.isModalOpen = true;
		},
		handleSearch() {},
		async onCancelModal(payload) {
			this.isModalOpen = payload;
			await this.reloadData();
		},
		onUpdateModal(payload) {
			console.log({ data: payload });
		},
		async reloadData() {
			let rawContacts = [];

			if (this.supplierType === 2) {
				rawContacts = await getFilmCommissionContacts(this.supplierId);
			} else {
				rawContacts = await getSuppAttributeContacts(this.supplierId);
			}

			this.rawContacts = rawContacts;
			this.contacts = this.rawContacts;
			this.contacts = this.contacts.map((contact) => {
				const fullName = contact.firstName + ' ' + contact.name;
				return {
					fullName,
					...contact
				};
			});
			this.selectedContact = this.contacts;
		}
	},
	validations: {
		selectedContact: {
			required
		}
	},
	watch: {
		isSubmitted: {
			handler(n) {
				if (!isNil(n) && n === true) {
					this.$v.$touch();
					if (this.$v.$invalid) {
						this.$emit('contact-selector:invalid', true);
					} else {
						this.$emit('contact-selector:invalid', false);
					}
				}
			},
			deep: true
			// immediate: true
		}
	}
};
</script>
<style lang="scss" scoped>
button.vs__deselect {
	display: none !important;
}
</style>
