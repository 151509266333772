var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.error, warning: _vm.warning } },
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(1, "Name"),
                            "label-for": "name",
                            "label-cols": 3,
                            horizontal: true,
                          },
                        },
                        [
                          _c("b-form-input", {
                            class: {
                              "is-invalid":
                                _vm.isSubmitted && _vm.$v.contact.name.$error,
                            },
                            attrs: {
                              type: "text",
                              placeholder: _vm.FormMSG(2, "Name ..."),
                            },
                            model: {
                              value: _vm.contact.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contact,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contact.name",
                            },
                          }),
                          _vm.isSubmitted && !_vm.$v.contact.name.required
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        12,
                                        "Please, length should be at least 2"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(3, "First Name"),
                            "label-for": "firstName",
                            "label-cols": 3,
                            horizontal: true,
                          },
                        },
                        [
                          _c("b-form-input", {
                            class: {
                              "is-invalid":
                                _vm.isSubmitted &&
                                _vm.$v.contact.firstName.$error,
                            },
                            attrs: {
                              type: "text",
                              placeholder: _vm.FormMSG(4, "First Name ..."),
                            },
                            model: {
                              value: _vm.contact.firstName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contact,
                                  "firstName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contact.firstName",
                            },
                          }),
                          _vm.isSubmitted && !_vm.$v.contact.firstName.required
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        13,
                                        "Please, length should be at least 2"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(5, "Phone Number"),
                            "label-for": "phone",
                            "label-cols": 3,
                            horizontal: true,
                          },
                        },
                        [
                          _c("b-form-input", {
                            class: {
                              "is-invalid":
                                _vm.isSubmitted && _vm.$v.contact.phone.$error,
                            },
                            attrs: {
                              type: "text",
                              placeholder: _vm.FormMSG(6, "Phone Number ..."),
                            },
                            model: {
                              value: _vm.contact.phone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contact,
                                  "phone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contact.phone",
                            },
                          }),
                          _vm.isSubmitted &&
                          !_vm.$v.contact.phone.phoneNumberValid
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        14,
                                        "Please, enter valid phone number"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(7, "Email"),
                            "label-for": "email",
                            "label-cols": 3,
                            horizontal: true,
                          },
                        },
                        [
                          _c("b-form-input", {
                            class: {
                              "is-invalid":
                                _vm.isSubmitted && _vm.$v.contact.email.$error,
                            },
                            attrs: {
                              type: "text",
                              placeholder: _vm.FormMSG(8, "Email ..."),
                            },
                            model: {
                              value: _vm.contact.email,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contact,
                                  "email",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contact.email",
                            },
                          }),
                          _vm.isSubmitted && !_vm.$v.contact.email.emailValid
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        15,
                                        "Please, enter valid email"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(9, "Function"),
                            "label-for": "function",
                            "label-cols": 3,
                            horizontal: true,
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "text",
                              placeholder: _vm.FormMSG(10, "Function ..."),
                            },
                            model: {
                              value: _vm.contact.function,
                              callback: function ($$v) {
                                _vm.$set(_vm.contact, "function", $$v)
                              },
                              expression: "contact.function",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "w-100" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "pull-right px-0",
                              attrs: { cols: "3" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        type: "submit",
                                        variant: "primary",
                                        size: "md",
                                        block: "",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.FormMSG(11, "Save"))),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }